<template>
  <div class="orderlist">
    <transition name="fade">
      <preLoadingrem v-if="isLoading"></preLoadingrem>
    </transition>
    <!-- 头部 -->
    <van-nav-bar
      title="确认订单"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="100"
    />
    <!-- 用户基本信息 -->
    <div class="orderbody">
      <section @click="goAddress">
        <span class="iconfont icon-dingwei"></span>
        <ul>
        
          <li>
            {{ defAddressLists.province }}{{ defAddressLists.city
            }}{{ defAddressLists.county }}
          </li>
          <li>{{ defAddressLists.address }}</li>
            <li>
            <span style='
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #7E7E7E;
            line-height: .3rem;
            '>{{ defAddressLists.consignee }}</span
            >&emsp;
            <span style='
            font-size: .24rem;
            font-family: SF Pro Text;
            font-weight: 500;
            color: #7E7E7E;
            line-height: .3rem;
            '>{{ defAddressLists.phone }}</span>
          </li>
        </ul>
        <span class="iconfont icon-qianjin" style='font-size:.24rem'></span>
      </section>
      <!-- 订单列表 -->
      <div  class='card-center'>
      <div class='shop-card' v-for="item in orderReqeust.skuGoodsList" :key="item.spuId">
        <van-card
          :num="item.num"
          :desc="item.color"
          :price="(item.price / 100.0).toFixed(2)"
          :title="item.title"
          :thumb="item.img"
        ></van-card>
        <!-- <van-field
          v-model="item.remark"
          label="订单备注"
          type="textarea"
          placeholder="选填,建议先和商家协调一致"
          rows="1"
          autosize
          :maxlength="50"
        /> -->
      </div>
      </div>
    </div>
    <!-- 运费 -->
    <section class="transfee">
      <p
        class="purchase"
        v-if="
          orderInfo.internalScore != '0' && orderInfo.internalScore != undefined
        "
      >
        <span style="font-size: 0.24rem; color: #cf4639">合计内购会积分：</span>
        <span style="font-size: 0.24rem; color: #cf4639">{{
          orderInfo.internalScore
        }}</span>
      </p>
      <!-- <p style="font-size: 0.32rem"> -->
        <!-- <span class="dark_text">合计运费：</span>
        <span class="dark_text">{{ (transFeeString / 100).toFixed(2) }} 元</span
        >&emsp; -->
        <!-- <span style="font-size: 0.8rem; color: #cf4639" class="score"
          >合计积分：</span
        >
        <span style="font-size: 0.8rem; color: #cf4639">{{
          orderInfo.score
        }}</span> -->
      <!-- </p> -->
    </section>
    <!-- 支付方式 -->
    <div class="payways">
      <!-- 发票 -->
      <!-- <van-cell title="发票" value="电子发票" is-link /> -->
      <!-- 充值卡 -->
      <!-- 优惠券单元格 -->
      <div class='money-list'>
      <van-cell
        @click="goShowList()"
        title="代金券"
        :value="`${couponLists.length}张可用`"
        v-if="couponLists.length && !couponMoney"
      />
      <van-cell
        style="font-size: 0.28rem"
        @click="goShowList()"
        title="代金券"
        :value="`优惠【${
          couponMoney > remainMoney ? muchMoney / 100 : couponMoney / 100
        }】元`"
        v-else-if="couponLists.length && couponMoney"
      />
      <van-cell title="代金券" value="暂无可用" v-else></van-cell>

      <van-cell class='cell-radero' title="运费" :value="transFeeStringText" ></van-cell>
      <van-cell class='cell-radero' title="商品金额" :value="` ￥${(remainMoney/100).toFixed(2)}`" ></van-cell>
      
      </div>

      <!-- 优惠券列表 -->
      <van-popup
        v-model="showList"
        position="bottom"
        :style="{ height: '70%' }"
        round
        @click-overlay="
          couponId = [];
          couponMoney = 0;
        "
      >
        <h1 class='coupon-title'>优惠卷</h1>
        <van-checkbox-group
          v-model="couponId"
          icon-size="18px"
          checked-color="#d84b41"
        >
          <ul
            v-for="(coupon, index) in couponLists"
            :key="coupon.id"
            class="coupon-item"
            @click="toggle(index)"
          >
            <li style="height: 1.28rem" class="coupon-item-info">
              
              <p :class="{ active: coupon.status != 1 }">
                <span class="coupon-value">{{ coupon.discount / 100 }}</span>
                <span class="coupon-unit">元</span>
              </p>
              <p>
                <span
                  class="coupon-name"
                  :class="{ active: coupon.status != 0 }"
                  >{{ coupon.title }}</span
                >
                <!-- <span class="coupon-time"
                  >有效期:{{
                    coupon.createTime.split(" ")[0].replace(/\-/g, ".")
                  }}-{{
                    coupon.expireTime.split(" ")[0].replace(/\-/g, ".")
                  }}</span
                > -->
                <span v-if="coupon.type != 2"
                  >使用规则：满{{ coupon.min / 100 }}元即可使用</span
                >
                <span style="display: block" v-if="coupon.status == 2"
                  >使用日期：{{ coupon.expireTime }}</span
                >
              </p>
            </li>
            <li>
              <van-checkbox :name="coupon.id" ref="checkboxes"></van-checkbox>
            </li>
          </ul>
        </van-checkbox-group>
        <p class="finised-btn">
          <Btn class='coupon-save' @click.native="handleCoupon">完成</Btn>
        </p>
      </van-popup>
      <!-- 现金劵 -->
      <van-collapse v-model="activeNames" v-if="deposit">
        <van-collapse-item
          title="现金劵"
          name="1"
          :value="`${rechargeTotalMoney.toFixed(2)}`"
        >
          <ul class="self-pays">
            <li>
              <van-field
                v-model="rechargeMoney"
                type="number"
                clearable
                @input="handlerInput"
                disabled
              >
                <span class="iconfont icon-renminbi" slot="left-icon"></span>
                <van-checkbox
                  v-model="checked"
                  slot="button"
                  @change="handlerChange"
                  >使用</van-checkbox
                >
              </van-field>
              <p style="font-size: 0.24rem; color: #de9000">
                注：满168才能使用20元的现金劵！
              </p>
            </li>
          </ul>
        </van-collapse-item>
      </van-collapse>
      <!-- 支付方式 -->
      <div class='money-list' style='margin-top: 0.2rem'>

         <van-cell
      title="开具发票"
      @click="goInvoice"
      is-link
      style="margin-top:0.12rem"
      :value="formData.invoice"
      ></van-cell>

      <van-cell
        v-if="isFoodShow"
        title="支付方式"
        value="请选择支付方式"
        is-link
        @click="onClick"
        style="margin-top: 0.12rem"
      >
        <p v-if="payMethodSelect == 1">
          <span
            class="iconfont icon-weixinzhifu"
            slot="value"
            style="color: #6ecd01; margin-right: 6px"
          ></span
          >微信
        </p>

        <p v-if="payMethodSelect == 2">
          <img src="../../../static/images/zfb.png" class="zfb-img" />
          支付宝
        </p>
      </van-cell>
      <!-- <van-cell
        title="支付方式"
        value="电子发票"
        is-link
        v-else
        @click="onClick"
        style="margin-top: 0.4rem"
      >
        <p>线下支付</p>
      </van-cell> -->
      <!-- 去开发票 -->
     
      </div>
      <!-- <div class="invoice-box">
        <van-button round type="info" @click="goInvoice" class="invoice" color="linear-gradient(to right, #e67249, #cf4535)">去开发票</van-button>
      </div> -->
    </div>
    <!-- 下单按钮 -->
    <div class="footerSumbit">
      <p style="font-size: 0.28rem" v-cloak>
        共计：￥{{ (remainMoney / 100).toFixed(2) }}元
      </p>
      <p>
        <Btn
          @click.native="pay($event)"
          v-preventReClick="5000"
          :class="{ clicked: isClicked }"
          >立即下单</Btn
        >
      </p>
    </div>
    <!-- 支付确认 -->
    <van-dialog
      v-model="showPay"
      title="确认付款?"
      show-cancel-button
      cancel-button-color="#ccc"
      @confirm="confirmHandle"
      @cancel="cancelHandle"
    >
      <p style="text-align: center; font-size: 0.38rem; line-height: 0.64rem">
        ￥{{ (remainMoney / 100).toFixed(2) }}
      </p>
    </van-dialog>
    <!-- 选择支付方式 -->
    <van-popup v-model="showPayMethod" position="bottom" round>
      <van-radio-group
        v-model="payMethodSelect"
        @change="handlePayMethod"
        class="radio-group-box"
      >
        <van-radio name="1" style="font-size: 0.36rem">
          <!-- <div> -->
          <span
            class="iconfont icon-weixinzhifu"
            style="color: #6ecd01; margin-right: 6px"
          ></span
          >微信
          <!-- </div> -->
        </van-radio>
        <van-radio
          v-if="showzfb"
          name="2"
          class="zfb"
          style="font-size: 0.36rem"
        >
          <img src="../../../static/images/zfb.png" class="zfb-img" />

          支付宝</van-radio
        >
      </van-radio-group>
    </van-popup>
    <!-- 海外商品未认证弹窗 -->
     <van-popup
      v-model="showpPolicy"
      :overlay-style="{ backgroundColor: 'rgba(0,0,0,0.3)' }"
      :close-on-click-overlay="false"
      class="popupContent"
    >
      <div class="title-h2">认证提示</div>
      <div class="popupContent-div">
        
        <p class="text-p">
          <span style="color:#333" class="briefIntroduction">尊敬的用户：</span><br /> 
          <span class='text-v'>该订单含有海淘商品，需要完成身份确认！ </span>
          <br>
          
        </p>
      </div>
       <div class="confirm-toUpdate">
        <van-button  class="confirm-btn-toUpdate btn" @click="showpPolicyCancel"> 取消</van-button>
        <van-button  class="confirm-btn-toUpdate btn" @click="showpPolicyAuthentication"> 去认证</van-button>
      </div>

    </van-popup>
  </div>
</template>

<script>
import Heador from "./heador";
import Btn from "../common/btn";
// import $axios from "axios";
import wx from "weixin-js-sdk";
import {
  Card,
  NavBar,
  Collapse,
  CollapseItem,
  Field,
  Checkbox,
  CheckboxGroup,
  DropdownMenu,
  DropdownItem,
  Cell,
  CouponCell,
  CouponList,
  Popup,
  RadioGroup,
  Radio,
  Stepper,
  Dialog,
} from "vant";
let accessToken = localStorage.getItem("token");
export default {
  data() {
    return {
      orderList: [],
      couponId: [],
      orderInfo: {}, // 相关与订单相关信息
      deposit: 0, // 现金劵金额
      chosenCoupon: -1,
      couponLists: [], // 优惠卷列表
      showList: false, // 展示优惠卷列表判断
      isLoading: false, // 开始图的加载
      activeNames: ["-1"],
      rechargeMoney: "", // 输入充值卡金额
      couponMoney: 0, // 选中优惠劵金额
      checked: false,
      isClicked: false,
      isHotOrder: 0,
      isNeedIdentityCheck: false,
      deviceEnvir: this.Base.deviceEnvir(), // 设备环境判断
      utf16toEntities: this.Base.utf16toEntities,
      showPay: false, // 支付确认是否打开
      actualOrderId: "", // 真实下单后的id
      isPlusTrans: null, // 是否增加运费
      plusTrans: null, // 运费
      isNeedChangeAddress: null,
      size: undefined,
      orderReqeust: {
        skuGoodsList: [],
      },
      defAddressLists: {},
      transFeeString: "", //运费
      transFeeStringText:'',//运费展示
      showPayMethod: false, //支付方式弹窗
      payMethodSelect: "1", //支付方式
      //支付宝显示隐藏
      showzfb: true,
      isFoodShow: true,
      muchMoney: Number,
      deviceEnvir: this.Base.deviceEnvir(),
      showpPolicy: false,
      formData:{},
      orderStatus: this.$route.query.orderStatus
    };
  },
  components: {
    Heador,
    [Card.name]: Card,
    Btn,
    [NavBar.name]: NavBar,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cell.name]: Cell,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
  },
  created() {
    // 开具发票抬头
    // if (JSON.parse(localStorage.getItem("formData")) !== undefined) {
    //   this.formData = JSON.parse(localStorage.getItem("formData"))
    // }else{
      
    // }
   
  
    // console.log(this.formData);
    this.getDefAddressList();
    // 获取订单信息
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });
    //获取进入页面时的价格

    // this.getOrderDetail();

    //支付宝是否显示
    if (this.$methods.isWeChat()) {
      this.showzfb = false;
    }

    //苹果微信支付回调
    window.wxPayCallBack = this.wxPayCallBack;


    // this.orderReqeust.skuGoodsList = this.$api.globalData.skuGoodsList;
    this.orderReqeust.skuGoodsList = JSON.parse(
      localStorage.getItem("skuGoodsList")
    );

   
    console.log( this.orderReqeust.skuGoodsList);
    this.orderReqeust.skuGoodsList.forEach(v=>{
      if(v.color){

        v.color = '规格: ' + v.color
      }else{
        v.color = '规格: ' + v.skuTitle

      }
    })

    // console.log(
    //   "this.orderReqeust.skuGoodsList",
    //   this.orderReqeust.skuGoodsList
    // );
    //判断支付方式是否显示
    if (this.orderReqeust.skuGoodsList[0].isFood === "true") {
      // console.log("isFood");
      this.isFoodShow = false;
    } else {
      this.isFoodShow = true;
    }
    this.getUserCouponsList();
    this.getCacheGet();
    
  },
  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },
  mounted() {
    window.invokeJS = this.invokeJS;

    //获取进入页面第一次总价
    let money = 0;
    let moneyArr = JSON.parse(JSON.stringify(this.orderReqeust.skuGoodsList));
    moneyArr.forEach((v) => {
      money += v.price * v.num;
    });
    this.muchMoney = money;

    
  },
  computed: {
    // 订单总金额
    totalMoney() {
      this.allSkuListMoney = this.orderReqeust.skuGoodsList.reduce(
        (total, item) => {
          return (total += item.price * item.num);
        },
        0
      );
      let actualMoney = this.allSkuListMoney + this.transFeeString;
      return actualMoney;
      // return this.orderInfo.actualMoney; // 订单费用加运费
    },
    // 还需支付的金额
    remainMoney() {
      let money = this.subtr(
        this.subtr(this.totalMoney, this.rechargeMoney),
        this.couponMoney
      );

      if (money >= 0) {
        // 用户输入金额正确的情况下才进行显示正常金额
        return money;
      } else if (this.couponMoney > 0) {
        return 0;
      } else {
        return "----";
      }
    },
    // 充值卡余额
    rechargeTotalMoney() {
      return Number(this.deposit);
    },
  },
  methods: {
 
    toggle(index) {
      this.$refs.checkboxes.forEach((item, idx) => {
        if (index != idx) {
          item.toggle(false);
        }
      });
      this.$refs.checkboxes[index].toggle();
    },
    subtr(arg1, arg2) {
      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // 选中优惠劵
    handleCoupon() {
      let couponMoney = 0;
      this.couponLists.forEach((item) => {
        if (this.couponId.indexOf(item.id) != -1) {
          couponMoney += item.discount;
        }
      });
      this.couponMoney = couponMoney;
      this.showList = false;
    },
    goShowList() {
      if(this.orderStatus == 10){
        return
      }
      this.showList = true
    },
    // 获取预下单信息
    getOrderDetail() {
      // let orderId = this.$route.params.orderId;

      this.isLoading = false;

      // this.$server.orderApi
      //   .preOrderDetailAddress({
      //     accessToken: localStorage.getItem("token"),
      //     orderId,
      //   })
      //   .then((res) => {
      //     if (res.code === 100) {
      //       this.deposit = res.returnValue.deposit;
      //       this.orderInfo = res.returnValue.order;
      //       this.couponLists = res.returnValue.couponList;
      //       this.orderList = res.returnValue.order.listGoodsOrderDetailVO;
      //       this.isLoading = false;
      //       this.isPlusTrans = res.returnValue.isPlusTrans;
      //       this.plusTrans = res.returnValue.plusTrans;
      //       this.isNeedChangeAddress = res.returnValue.isNeedChangeAddress;
      //       this.isNeedIdentityCheck = res.returnValue.isNeedIdentityCheck;
      //       this.isHotOrder = res.returnValue.isHotOrder;
      //       console.log(this.isNeedChangeAddress);
      //       console.log(this.isPlusTrans);
      //       if (this.isPlusTrans) {
      //         Dialog.alert({
      //           title: "运费",
      //           message: `该笔订单需增加运费${this.plusTrans}元`,
      //           width: "250",
      //         }).then(() => {
      //           // on close
      //         });
      //       }
      //       if (this.isNeedChangeAddress) {
      //         Dialog.alert({
      //           title: "运费",
      //           message: `${res.returnValue.message}`,
      //           width: "250",
      //         }).then(() => {
      //           // on close
      //         });
      //       }
      //     } else {
      //       this.$toast(res.message);
      //       this.isLoading = false;
      //     }
      //   });
    },
    getUserCouponsList() {
      this.$api.order
        .getUserCouponsAddress({
          userId: localStorage.getItem("customerId"),
          money: this.orderReqeust.skuGoodsList[0].price,
        })
        .then((res) => {
          // console.log("优惠券列表", res);
          if (res.errno === 200) {
            this.couponLists = res.data;
          }
        });
    },
    getDefAddressList() {
      this.$api.order
        .getDefAddress({
          userId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          // console.log("获取用户默认地址", res);
          if (res.errno === 200) {
            this.defAddressLists = res.data;
             if(sessionStorage.isOpenRutern){
                this.goOrder()
              }

            let skuList = this.orderReqeust.skuGoodsList;
            let orderRequestDTO = {
              addressId: this.defAddressLists.id,
              skuList,
            };
            this.$api.order
              .getFreightPriceAddress({
                userId: localStorage.getItem("customerId"),
                orderRequestDTO: JSON.stringify(orderRequestDTO),
              })
              .then((res) => {
                console.log("得到订单的运费", res);
                if (res.errno === 200) {
                  this.transFeeString =res.data;
                  this.transFeeStringText = ' ￥' + (res.data / 100);
                }
              });
          }
        });
    },

    //苹果微信支付回调
     wxPayCallBack(params){
      //  this.$toast('苹果回调')
      //  this.$toast(typeof(params))

        this.$toast.loading({
        duration: 5000, // 持续展示 toast
        forbidClick: true,
        // message: "登录中2" + JSON.stringify(paramsObj.code),
        message: JSON.stringify(params),
      });

      if(params == 0){
       this.$toast('支付成功')
       window.location.href= `${window.location.href.split("#")[0]}#/myorder`
     

      }else{
       this.$toast('支付失败')

      }

     },

    // getDefAddressList() {
    //   let orderRequestDTO = {
    //     addressId: this.defAddressLists.id,
    //   };
    //   this.$api.order
    //     .getFreightPriceAddress({
    //       userId: localStorage.getItem("customerId"),
    //       orderRequestDTO: JSON.stringify(orderRequestDTO),
    //     })
    //     .then((res) => {
    //       console.log("获取用户默认地址", res);
    //       if (res.errno === 200) {
    //         this.defAddressLists = res.data;
    //       }
    //     });
    // },
    // 支付方式提示
    onClick() {
      this.$toast("暂只支持微信支付");
      // this.showPayMethod = true;
    },
    goOrder(){
       sessionStorage.removeItem('isOpenRutern')
      this.pay();
    },
    handlePayMethod(e) {
      this.showPayMethod = false;
    },
    // 点击复选框的时候触发
    handlerChange(value) {
      if (value) {
        // 充值卡金额大于等于订单总额的时候，全部等于订单总额
        if (this.rechargeTotalMoney >= this.totalMoney) {
          this.rechargeMoney = this.totalMoney;
        } else {
          // 充值卡小于订单总额时，全部等于充值卡余额
          this.rechargeMoney = this.rechargeTotalMoney;
        }
      } else {
        this.rechargeMoney = "";
      }
    },
    // 输入框的input事件
    handlerInput(value) {
      this.moneyRight(value);
      // 全部按钮的控制
      if (value === "") {
        this.checked = false; // 全选按钮为false
      }
    },
    // 判断金额是否正确的方法
    moneyRight(value) {
      // 正则表达式,验证输入格式 可以为空,非负数，小数不超过两位的金额
      let regRule = /^$|^[0-9]+(.[0-9]{1,2})?$/;
      const isRight = regRule.test(value);

      // 充值卡金额大于等于订单总金额的情况;
      if (this.rechargeTotalMoney >= this.totalMoney) {
        if (!(isRight && value <= this.totalMoney)) {
          // 如果不符合正则
          this.$toast({
            message: `请输入0-${this.totalMoney}的正确金额`,
            duration: 800,
          });
          return false;
        } else {
          return true;
        }
      } else {
        // 充值卡金额小于订单总金额的情况
        if (!(isRight && value <= this.rechargeTotalMoney)) {
          // 如果不符合正则
          this.$toast({
            message: `请输入0-${this.rechargeTotalMoney}的正确金额`,
            duration: 800,
          });
          return false;
        } else {
          return true;
        }
      }
    },
    // 下单前的校验
    pay(event) {

      // if (this.isNeedIdentityCheck) {
      //   Dialog.alert({
      //     title: "认证",
      //     message: `购买海淘商品需先完成认证`,
      //     confirmButtonText: "去认证",
      //     confirmButtonColor: "#DE4245",
      //     width: "250",
      //   }).then(() => {
      //     this.$router.replace("/changename");
      //   });
      //   return;
      // }

      // let flag = this.moneyRight(this.rechargeMoney); // 接收验证方法的返回值
      // if (flag) {
      //   // 用户输入金额正确的时候才允许下单
      //   this.isClicked = true; // 提示已经点击
      //   // 点击太过频繁
      //   if (event.target.disabled) {
      //     return false;
      //   }
      //   this.payOrder();
      // }
      if (this.$methods.isWeChat()) {
      
      if(!sessionStorage.openId){
          let appid = localStorage.getItem('appid');

        // let url = location.href.split('#/')[0] + "/#/getOpenId";
        // console.log(encodeURIComponent(url));
        // let url = 'http://service.mall.hongyuantrading.com/wx/redirect';
        let url = `${this.$globalConfig.redirectuUrl}/wx/redirect`;
        url = encodeURIComponent(url);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
        return
      }
      }
      this.payOrder();
      setTimeout(() => {
        this.isClicked = false;
      }, 5000);
    },
    // 立即下单
    payOrder() {
      this.isLoading = true;
      // 商品参数
      // let remark = {}; // 备注对象
      // this.orderList.forEach((value, index) => {
      //   remark[value.id] = this.utf16toEntities(value.remark);
      // });

      // // 下单需要传递的参数
      // let params = {
      //   accessToken: localStorage.getItem("token"),
      //   remark: JSON.stringify(remark),
      //   couponIds: this.couponId.join(),
      //   deposit: this.rechargeMoney,
      //   orderId: this.$route.params.orderId,
      //   cartList: sessionStorage.getItem("cartList"),
      // };
      // // 下单
      // this.$server.orderApi.confirmOrderAddress(params).then((res) => {
      //   if (res.code === 100) {
      //     // 支付确认界面打开
      //     this.actualOrderId = res.returnValue.orderId;

      //     // 清除会话存储 购物车id
      //     if (sessionStorage.removeItem("cartList")) {
      //       sessionStorage.removeItem("cartList");
      //     }
      //     if (res.returnValue.isHotOrder == "1") {
      //       this.$router.push({
      //         path: "/myorder",
      //       });
      //       return;
      //     }
      //     // 打开确认付款界面
      //     this.showPay = true;
      //   } else {
      //     this.$toast(res.message);
      //   }
      // });

      // console.log('this.couponId',this.couponId, this.couponId.join());
      // return
      if (this.payMethodSelect == 1) {
        this.payChannel = "WX";
      }
      if (this.payMethodSelect == 2) {
        this.payChannel = "ALI";
      }
      let coupon = {
        id: this.couponId.join(),
      };
      
      let shareUserId = localStorage.getItem("shareUserId")
      console.log('shareUserId分享id', shareUserId);
      let paramsList = {
        skuList: this.orderReqeust.skuGoodsList,
        totalOriginalPrice: this.allSkuListMoney,
        totalPrice: this.allSkuListMoney,
        coupon,
        addressId: this.defAddressLists.id,
        freightPrice: this.transFeeString,
        takeWay: this.orderReqeust.skuGoodsList[0].takeWay, // cart buy
        skuOriginalId:this.orderReqeust.skuGoodsList[0].skuOriginalId,
        shareUserId: shareUserId ? shareUserId : ''
      };
      // console.log(paramsList);
      
      let formData = JSON.parse(localStorage.getItem("formData"))//取出开具发票数据
      // 合并paramsList formData
      let paramsList_formData = Object.assign(paramsList,formData)
           
      console.log(paramsList_formData);

      let skuGoodsList=JSON.parse(localStorage.skuGoodsList)
      console.log(skuGoodsList);
      let skuGoodsListID=undefined
      if(skuGoodsList[0].takeWay == "cart"){
          skuGoodsListID = skuGoodsList[0].orderNo

      }

      this.$api.order
        .takeOrderAddress({
          userId: localStorage.getItem("customerId"),
          orderRequest: JSON.stringify(paramsList_formData),
          channel: this.$methods.deviceEnvir(),
          payChannel: this.payChannel,
          orderNum : skuGoodsListID
        })
        .then((res) => {
          console.log("下单", res);
          if (res.errno === 20008) {
            this.showpPolicy = true;
          }
          if (res.errno === 200) {
            this.actualOrderId = res.data.id;
            this.orderNo = res.data.orderNo;
            this.actualPrice = res.data.actualPrice;
            // this.$router.push({
            //   path: "/myorder",
            // });
              // this.$api.order.getSign({
              //     orderId:this.orderNo
              // }).then(res=>{
              //   console.log(res,213821796218946);
              // })

            if (this.orderReqeust.skuGoodsList[0].isFood === "true") {
              //线下支付
              this.$api.order
                .offlinePrepay({
                  orderNo: res.data.orderNo,
                  userId: localStorage.getItem("customerId"),
                })
                .then((res) => {
                  if (res.errno === 200) {
                    this.$toast.success("支付成功");
                    this.$router.push({
                      path: "/myorder",
                      query: {
                        active: "2",
                      },
                    });
                  }
                });
              return;
            }

            if (this.remainMoney <= 0) {
              this.$api.order
                .noPrepay({
                  orderNo: res.data.orderNo,
                  userId: localStorage.getItem("customerId"),
                })
                .then((res) => {
                  if (res.errno === 200) {
                    this.$toast.success("支付成功");
                    window.location.replace(
                      `${window.location.href.split("#")[0]}#/myorder`
                    );
                  }
                });

              return;
            }
            this.showPay = true;
          } else {
            this.$toast({
              message: res.errmsg,
              duration: 2500,
            });
            this.isLoading = false;
          }
        });
    },
    // 海外弹窗取消按钮
    showpPolicyCancel(){
      this.showpPolicy = false;
    },
    // 海外弹窗去认证按钮
    showpPolicyAuthentication(){
      this.$router.push({path:'/changename'})
    },
    // 确认付款
    confirmHandle() {
      this.isLoading = false;

      // if (this.isFood === "true") {
      //   this.$api.home.offlinePrepay;
      //   return;
      // }
      // this.putPay();
      // console.log("this.payChannel", this.payChannel);
      // 支付宝
      if (this.payChannel == "ALI") {
        this.zfbPay();
      }
      // 微信
      if (this.payChannel == "WX") {
        this.wxPay();
      }
    },
    zfbPay() {
      // console.log(this.orderNo);
      // console.log(this.actualPrice);
      // return;
      this.$router.push({
        path: "/aliPay",
        query: {
          orderNo: this.orderNo,
          actualPrice: this.actualPrice,
        },
      });
    },
    // code() {
    //   console.log(998999);
    // this.$api.home.getCode((res) => {
    //   console.log(111);
    //   console.log(res, 8888);
    // });
    // $axios({
    //   url: "/wx/openIndex",
    //   methods: "get",
    // })
    //   .then((response) => {
    //     // 因为层级比较深，匿名函数会导致this指向发生改变
    //     // 这个时候使用箭头函数解决
    //     this.$toast(response.data);

    //     console.log(response);
    //   })
    //   .catch(function () {
    //     alert("请求失败");
    //   });

    // var url = `${window.location.href.split("#")[0]}#/payResult`;

    // var encodeUrl = encodeURIComponent(url);
    // window.location.replace(
    //   "http://47.107.71.240:8332//wx/openIndex" + "&redirect_url=" + encodeUrl
    // );

    //   var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    //   var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
    //   var context = "";
    //   if (r != null) context = r[2];
    //   reg = null;
    //   r = null;
    //   console.log(
    //     context == null || context == "" || context == "undefined"
    //       ? ""
    //       : context
    //   );
    //   return context == null || context == "" || context == "undefined"
    //     ? ""
    //     : context;
    // },

    wxPay() {
      // console.log(this.$methods);
      // this.$toast('123')
      // console.log(this.deviceEnvir);

      if (this.$methods.isWeChat()) {
        // 微信内支付
      // this.$toast('234')

        this.$api.order
          .wxPrepayAddress({
            orderNo: this.orderNo,
            payType: 1,
            openId: sessionStorage.getItem("openId"),
            ip: window.location.hostname,
          })
          .then((res) => {
            // console.log("微信内支付", res);

              // this.$toast("微信内支付")

            if (typeof WeixinJSBridge === "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
              }
            } else {
              this.onBridgeReady(res.data);
            }
          });
      } else {
        // 微信外支付
      // this.$toast('微信外支付')


      switch (this.deviceEnvir) {
        case "IOS":
      // this.$toast('ios支付')

           this.$api.order
          .wxPrepayAddress({
            orderNo: this.orderNo,
            payType: 2,
            // openId: 'ovw1Utysh2RHoqb7JXBaZGMyrtIY',
            openId: '',
            ip: window.location.hostname,
          })
          .then((res) => {
            // console.log("微信外支付", res);
            // this.$toast("微信外支付-ios")

            if (res.errno === 200) {
               let arr = {
           partnerid:'1549618731',
            prepayid:res.data.prepayId,
            package:res.data.packageValue,
            noncestr:res.data.nonceStr,
            timestamp:res.data.timeStamp,
            sign:res.data.sign
          }

           const paramsObj = { cmd: "009", ...arr };
                window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
            
            } else {
              this.$toast({
                message: res.errmsg,
                duration: 1500,
              });
            }
          });
          break;
        case "ANDROID":
      // this.$toast('安卓支付')

            this.$api.order
          .wxPrepayAddress({
            orderNo: this.orderNo,
            payType: 3,
            openId: '',
            ip: window.location.hostname,
          })
          .then((res) => {
            console.log("微信外支付", res);
            // this.$toast("微信外支付-安卓")

            if (res.errno === 200) {
              // return
              var url = `${window.location.href.split("#")[0]}#/payResult`;
              var encodeUrl = encodeURIComponent(url);
           
              window.location.replace(
                res.data.mwebUrl + "&redirect_url=" + encodeUrl
              );
            } else {
           
              this.$toast({
                message: res.errmsg,
                duration: 1500,
              });
            }
          });
          break;
      }

        // this.$api.order
        //   .wxPrepayAddress({
        //     orderNo: this.orderNo,
        //     payType: 2,
        //     // openId: 'ovw1Utysh2RHoqb7JXBaZGMyrtIY',
        //     openId: '',
        //     ip: window.location.hostname,
        //   })
        //   .then((res) => {
        //     console.log("微信外支付", res);
        //     this.$toast("微信外支付")

        //   let arr = {
        //    partnerid:'1549618731',
        //     prepayid:res.data.prepayId,
        //     package:res.data.packageValue,
        //     noncestr:res.data.nonceStr,
        //     timestamp:res.data.timeStamp,
        //     sign:res.data.sign
        //   }

        //    const paramsObj = { cmd: "009", ...arr };
        //         window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);

        //     // this.$toast({
        //     //   message: "微信外部",
        //     //   duration: 1500,
        //     // });

        //     if (res.errno === 200) {
        //       // return
        //       var url = `${window.location.href.split("#")[0]}#/payResult`;
        //       // var url = "http://mv2apit.keplerlab.cn";
        //       var encodeUrl = encodeURIComponent(url);
        //       // var encodeUrl = url;
        //       // console.log(encodeUrl);
        //       // console.log(res.data.mwebUrl);
        //       // console.log(res.data.mwebUrl + "&redirect_url=" + encodeUrl);
        //       // return;
        //       window.location.replace(
        //         res.data.mwebUrl + "&redirect_url=" + encodeUrl
        //       );
        //     } else {
        //       // this.$toast({
        //       //   message: res,
        //       //   duration: 15000,
        //       // });
        //       this.$toast({
        //         message: res.errmsg,
        //         duration: 1500,
        //       });
        //     }
        //   });
      }
    },

    // 取消支付->去到订单详情
    cancelHandle() {
      this.$router.replace(`/orderdetail/${this.actualOrderId}`);
    },
    // 支付逻辑，最终与后台交互
    putPay() {
      let remark = {}; // 备注对象
      this.orderList.forEach((value, index) => {
        remark[value.id] = this.utf16toEntities(value.remark);
      });
      // H5支付,(从德合汇跳转到云楚新能源属于H5支付)
      const isDehe = localStorage.getItem("isDehe");
      const isHuawei = localStorage.getItem("isHuawei");
      const isSalesman = localStorage.getItem("isSalesman");
      // 判断公众号支付
      const openid = localStorage.getItem("openid");

      // d德合汇跳过来的就H5支付
      if (isDehe || isSalesman || isHuawei) {
        this.$server
          .getH5paramsAddress({
            accessToken: localStorage.getItem("token"),
            depositMoney: this.rechargeMoney,
            orderId: this.actualOrderId,
            remark: JSON.stringify(remark),
          })
          .then((res) => {
            if (res.code === 100) {
              this.goPayResult();
              switch (this.deviceEnvir) {
                case "IOS":
                  window.webkit.messageHandlers.invokeiOS.postMessage({
                    cmd: "010",
                    mweb_url: res.returnValue,
                  });
                  break;
                case "ANDROID":
                  var test = "http://dhmall.keplerlab.cn/dist/#/payResult";
                  var url = `${window.location.href.split("#")[0]}#/payResult`;

                  var encodeUrl = encodeURIComponent(url);
                  window.location.replace(
                    res.returnValue + "&redirect_url=" + encodeUrl
                  );
                  break;
              }
            } else {
              this.$toast(res.message);
              this.goPayResult();
            }
          });
        return false;
      }
      // 公众号支付
      if (openid) {
        // 走公众号支付和充值卡
        this.$server
          .payAddress({
            accessToken: localStorage.getItem("token"),
            orderId: this.actualOrderId,
            depositMoney: this.rechargeMoney,
            openid: localStorage.getItem("openid") || "",
            remark: JSON.stringify(remark),
          })
          .then((res) => {
            if (res.code === 100) {
              // 代表直接用充值卡就支付完毕
              if (res.returnValue == 1) {
                this.goPayResult();
              } else {
                // 充值卡余额不够，走了微信支付
                let params = { ...res.returnValue }; // 接收后台的返回值
                if (typeof WeixinJSBridge === "undefined") {
                  if (document.addEventListener) {
                    document.addEventListener(
                      "WeixinJSBridgeReady",
                      onBridgeReady,
                      false
                    );
                  } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                    document.attachEvent(
                      "onWeixinJSBridgeReady",
                      onBridgeReady
                    );
                  }
                } else {
                  this.onBridgeReady(params);
                }
                this.goPayResult();
              }
            } else {
              this.$toast(res.message);
            }
          });
        return false;
      }
      // APP支付
      if (this.deviceEnvir === "IOS") {
        // IOS支付
        // 获取支付需要的参数
        this.$server
          .getParamsAddress({
            accessToken: localStorage.getItem("token"),
            depositMoney: this.rechargeMoney,
            orderId: this.actualOrderId,
            remark: JSON.stringify(remark),
          })
          .then((res) => {
            if (res.code === 100) {
              if (res.returnValue == 1) {
                // 直接全部走充值卡支付
                this.goPayResult();
              } else {
                let params = res.returnValue;
                // 向IOS发送指令
                const paramsObj = { cmd: "009", ...params };
                window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
                this.goPayResult();
              }
            } else {
              this.$toast(res.message);
            }
          });
        return false;
      } else if (this.deviceEnvir === "ANDROID") {
        // 安卓支付
        // 获取支付需要的参数
        this.$server
          .getParamsAddress({
            accessToken: localStorage.getItem("token"),
            depositMoney: this.rechargeMoney,
            orderId: this.actualOrderId,
            remark: JSON.stringify(remark),
          })
          .then((res) => {
            if (res.code === 100) {
              if (res.returnValue == 1) {
                this.goPayResult();
                return false;
              } else {
                let params = res.returnValue;
                // 向安卓发送指令
                let paramsStr = JSON.stringify({ cmd: "009", ...params });
                window.android.invokeAndroid(paramsStr);
                this.goPayResult();
              }
            } else {
              this.$toast(res.message);
              this.goPayResult();
            }
          });
        return false;
      }
    },
    // 去到询问支付界面
    goPayResult() {
      setTimeout(() => {
        this.$router.replace("/payResult");
      }, 3000);
    },
    // 调起微信支付
    onBridgeReady(params) {
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          that.$router.replace("/myorder");
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          this.goPayResult();
        } else {
          that.$dialog
            .confirm({
              message: "支付失败",
              showCancelButton: false,
            })
            .then(() => {
              this.goPayResult();
            });
        }
      });
    },
    // 把订单id传过去
    goAddress() {
      if(this.orderStatus == 10){
        return
      }
      this.$router.push({
        path: "/addresslist",
        query: {
          // orderId: this.$route.params.orderId,
          orderId: "1",
        },
      });
    },
    // 返回
    onClickLeft() {
      this.$router.back(-1);
    },
    goInvoice() {
      this.$router.push({
        path: "/invoice",
        // query: {
        //   orderId: this.$route.params.orderId,
        // },
      });
    },

    // 获取缓存发票信息
    getCacheGet(){
      this.$api.order.getCache({
        accessToken:localStorage.getItem("token"),
        randomNumber:localStorage.getItem("randomNumber"),
      }).then((res) =>{
        console.log(res,"获取缓存发票信息");
        if (res.data) {
          this.formData = res.data
        }else{
          return;
        }
      })
    },
  },
  // 的beforeRouteLeave钩子函数
  beforeRouteLeave(to, from, next) {
    if (to.name !== "addresslist") {
      // 清除会话存储 购物车id
      sessionStorage.removeItem("cartList");
      // localStorage.removeItem("formData")
    }
    next();
  },


};
</script>

<style lang='less' scoped>
@import "../../less/element.less";
@red: #cf4639;
.orderlist {
  width: 100%;
  box-sizing: border-box;
  padding: 0.76rem 0 1.28rem;
  .van-nav-bar {
    letter-spacing: 1px;
    background-color: #fafafa;
    .van-icon {
      color: #ccc;
    }
    .van-nav-bar__title {
      // color: @red;
    }
  }
  .orderbody {
    padding: 0.32rem 0.32rem 0.015rem;
    & > section {
      padding: 0.32rem;
      background: #fff;
      margin-bottom: 0.2rem;
      border-radius: 0.15rem;

      box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
      border-radius: .2rem;
      .list(row);
      justify-content: space-between;
      align-items: center;
      ul {
        li {
          font-size: 0.25rem;
          line-height: 0.48rem;
          &:first-child {
            span {
              &:first-child {
                color: black;
              }
              &:last-child {
                color: rgb(156, 155, 155);
              }
            }
          }
          &:last-child {
            color: @ordinary_icon_color;
            font-size: 0.25rem;
          }
        }
      }
      & > span {
        font-size: 0.48rem;
        &:first-child {
          font-size: 0.64rem;
          color: @dingwei_icon_color;
        }
        &:last-child {
          color: #ccc;
        }
      }
    }
    .van-card {
      // border-radius: 0.5rem 0.5rem 0 0;
      background: #fff;
      padding: 0.32rem 0.32rem 0;
      margin: 0;
      &:last-child {
        padding-bottom: 0.32rem;
      }
      .van-card__content {
        .van-card__title {
          line-height: 0.56rem;
          font-size: .26rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: .36rem;
          max-height: .8rem;
        }
        .van-card__price {
          color: @price_text_color;
        }
      }
    }
  }
  & > .transfee {
    padding: 0 0.32rem;
    p {
      text-align: right;
      background: #fff;
      font-size: 0.25rem;
      padding: 0.32rem;
      border-radius: 0 0 0.16rem 0.16rem;
    }
    .purchase {
      padding-bottom: 0;
      border-radius: 0;
    }
  }
  & > .payways {
    width: 100%;
    box-sizing: border-box;
    margin: 0.2rem 0 0;
    padding: 0 0.32rem;
    /deep/ .van-cell {
      border-radius: 0.16rem;
    }
    // 优惠券弹窗
    .van-popup {
      background-color: #f5f5f5;
      width: 100%;
      box-sizing: border-box;
      padding: 0.24rem 0.24rem 0.96rem;
      h1 {
        text-align: center;
        color: black;
        margin: 0.16rem 0 0.32rem;
      }
      .coupon-item {
        padding: 0.3rem .32rem .28rem .54rem;
        border-radius: 0.16rem;
        box-sizing: border-box;
        margin: 0 0 0.24rem;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        font-size: 0.24rem;
        color: #bebebe;
        display: flex;
        justify-content: space-between;
        align-items: center;
         background: url('../../assets/images/coupon-list.png');
          background-size: cover;
          background-repeat:no-repeat;

          font-size: .22rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #7E7E7E;
          line-height: .58rem;
        li {
          span {
            line-height: 0.38rem;
          }
        }
        .coupon-item-info {
          display: flex;
          align-items: center;
          flex: 1;

          .coupon-value{
            font-size: .5rem !important;
          }
          p {
            &:first-child {
              color: #d84b41;
              flex: 2;
              &.active {
                // color: #8c8c8c;
                font-size: .5rem;
                font-family: SF Pro Display;
                font-weight: bold;
                color: #FFFFFF;
                line-height: .58rem;
              }
            }
            &:last-child {
              flex: 4;
            }
          }
          .coupon-value {
            font-size: 0.38rem;
            font-weight: bold;
          }
          .coupon-name {
            // font-size: 0.32rem;
            // color: black;
            display: block;
            // margin-bottom: 0.18rem;
            font-size: .3rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: .58rem;
            &.active {
            color: #333333;

              // color: #8c8c8c;
            }
          }
          .coupon-time {
            display: block;
          }
        }
      }
      .finised-btn {
        background-color: #fff;
        width: 100%;
        
        // box-sizing: border-box;
        padding:  0rem 0 0.16rem 0;
        left: 0;
        bottom: 0;
        z-index: 999;
        position: fixed;
        text-align: center;
        .btn {
          .btnmin(@bgcolor: @ordinary_btn_border_color, @color:#fff, @padding:0.5rem 0);
          width: 90%;
        }
      }
    }

    // 现金劵
    .van-collapse-item {
      margin: 0.06rem 0;
    }
    & /deep/ .van-collapse-item__content {
      padding: 0px 12px 6px;
    }
    & /deep/ .van-cell:not(:last-child)::after {
      border: none;
    }
    ul.self-pays {
      li {
        p {
          span {
            color: @ordinary_text_color;
            font-size: 0.21rem;
          }
        }
        &.other-pays {
          .list(row);
          justify-content: space-between;
        }
        & /deep/ .van-checkbox__label {
          color: #999;
          font-size: 0.24rem;
        }
        & /deep/ .van-field__body {
          border-bottom: 1px solid #f4f0f0;
        }
        & /deep/ .van-checkbox__icon--checked .van-icon {
          background-color: @radio_checked_color;
          border-color: @radio_checked_color;
        }
        & /deep/ .van-checkbox__icon {
          font-size: 0.28rem;
        }
      }
      .van-cell {
        padding: 10px 0;
      }
    }
  }
  .footerSumbit {
    padding: 0.16rem 0.32rem;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    bottom: 0;
    background-color: #fff;
    .list(row);
    justify-content: space-between;
    align-items: center;
    color: @price_text_color;
    font-size: 0.25rem;
    p {
      flex: 1;
      .btn {
        width: 100%;
        .btnmin(@padding:0.24rem 0, @size:0.25rem, @radius:0.06rem, @color:#fff);
        background: @ordinary_btn_color;
        &.clicked {
          background: #ccc;
        }
      }
    }
  }
}
.radio-group-box {
  padding: 25px;
}
// .van-radio {
// }
.zfb {
  margin-top: 20px;
}
[v-cloak] {
  /*属性选择器*/
  display: none;
}
.invoice-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.24rem /* 40/50 */;
}
.invoice {
  height: 36px;
}
//价格大小
.van-card__price-integer {
  font-size: 0.32rem;
}
.van-card {
  font-size: 0.24rem;
}
.zfb-img {
  vertical-align: middle;
  width: 0.32rem;
  height: 0.32rem;
}
 // 弹窗
  .popupContent {
    box-sizing: border-box;
    width: 5.4rem /* 270/50 */;
    // height: 8rem;
    max-height: 8rem /* 400/50 */;
    border-radius: 0.2rem /* 10/50 */;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    
    .popupContent-div{
      // margin-bottom: .68rem /* 34/50 */;
      padding: 0rem /* 23/50 */ 0.44rem /* 22/50 */ 0.4rem /* 20/50 */;
      overflow: scroll;
    }
    p {
      // text-indent: 2em;
      font-size: .28rem /* 14/50 */;
      font-family: PingFang SC;
      color: #333333;
    }
    .confirm {
      // margin-top: .4rem /* 20/50 */;
      width: 100%;
      font-size: 0.32rem /* 16/50 */;
      font-weight: 500;
      border-top: .02rem /* 1/50 */ solid #efefef;
      // position:fixed;
      left: 0;
      bottom: 0;
      // border: 0 .1rem /* 5/50 */ .1rem /* 5/50 */ 0;
      .confirm-btn{
        font-family: PingFang SC;
        height: .88rem /* 44/50 */;
        line-height:.88rem /* 44/50 */;
        width: 50%;
        border-left: none;
        border-top: none;
      }
      .btn{
        color: #bb1d1f;
        border-bottom-right-radius: .2rem;
        border-bottom: none;

      }
      .btns{
        color: #7e7e7e;
        border-bottom-left-radius: .2rem;
        border-bottom: none;

      }
    }
  }
    .title-h2 {
      padding-top: .4rem /* 20/50 */;
      padding-bottom: .24rem /* 12/50 */;
      text-align: center;
      font-size: .32rem /* 16/50 */;
      font-weight: 700;
    }
    .confirm-toUpdate{
      // margin-top: .4rem /* 20/50 */;
      width: 100%;
      font-size: 0.32rem /* 16/50 */;
      font-weight: 500;
      border-top: .02rem /* 1/50 */ solid #efefef;
      // position:fixed;
      left: 0;
      bottom: 0;
      .confirm-btn-toUpdate{
        font-family: PingFang SC;
        height: .88rem /* 44/50 */;
        line-height:.88rem /* 44/50 */;
        width: 50%;
        border-top: none;
        border-left: none;
        .btn{
          color: #bb1d1f;
        }
      }
    }
  .text-v{
    display: inline-block;
    margin-top: .05rem /* 20/50 */;
    line-height: 1.5;
  // text-indent: 2em;
  }
  .coupon-title{
    font-size: .28rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: .5rem;
  }
  .coupon-save{
    height: .9rem;
    background: linear-gradient(0deg, #EE3A3A, #F46161);
    border-radius: .1rem !important;
    font-size: .36rem !important;
    font-family: PingFang SC;
    font-weight: 500;
    padding: 0 !important;
    line-height: .9rem !important;
  }
  .van-card__desc{
    font-size: .26rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #AEAEAE;
    line-height: .36rem;
  }
  .money-list{
    background: #FFFFFF;
    box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
    border-radius: .2rem;
    overflow: hidden;
  }
  .cell-radero{
    border-radius: 0 !important;
  }
  .shop-card{
    // box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
    // border-radius: .2rem;
    overflow: hidden;
    // margin-bottom: .3rem;
  }
  /deep/ .van-field__control::-webkit-scrollbar{
    display: none;
  }
  .card-center{
    background: #fff;
    box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05);
    border-radius: .2rem;
    overflow: hidden;
    padding-bottom: .2rem;
  }
</style>
